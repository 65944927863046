import React from 'react';
import PropTypes from 'prop-types';
import { Box, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { graphql, useStaticQuery } from "gatsby"
const { slug } = require('../utils/tools.js');

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor:"white",
    color: "black",
    fontFamily: "FuturaBold",
    fontSize: "1rem",
    padding:"0.3rem 1rem",
    position: 'absolute',
    right: '1.5rem',
    textTransform: "uppercase",
    top: '1.5rem',
    zIndex: 2,
    [theme.breakpoints.down('lg')]: {
      padding:"0.2rem 0.8rem",
      fontSize: "0.8rem",
    },
    [theme.breakpoints.down('sm')]: {
      padding:"0.1rem 0.6rem",
      top: '0.5rem',
      right: '0.5rem',
      //fontSize: ".8rem",
    }
  },
  customPos:{
    position: 'relative',
    marginBottom: "0.8rem",
    marginTop: "-0.2rem",
    [theme.breakpoints.up('md')]: {
      top:"0.4rem",
      left:"-1rem",
      marginBottom: "1rem",
    },
  },
  label:{
    color: "inherit",
    textDecoration:"none",
  },
  economia: {
    backgroundColor: theme.palette.economia,
    color: "white"
  },
  politicas: {
    backgroundColor: theme.palette.politicas,
    color: "white"
  },
  internacionales: {
    backgroundColor: theme.palette.internacionales,
    color: "white"
  },
  'movimiento-obrero': {
    backgroundColor: theme.palette.movimiento,
    color: "white"
  },
  mujer: {
    backgroundColor: theme.palette.mujer,
    color: "white"
  },
  'sociedad-y-cultura': {
    backgroundColor: theme.palette.sociedad,
    color: "white"
  },
  revista: {
    backgroundColor: theme.palette.historia,
    color: "white"
  },
  archivo: {
    backgroundColor: theme.palette.archivo,
    color: "black"
  },
  juventud: {
    backgroundColor: theme.palette.juventud,
    color: "white"
  },
  'correo-de-lectores': {
    backgroundColor: theme.palette.correo,
    color: "white"
  },
  debate: {
    backgroundColor: theme.palette.debate,
    color: "white"
  },
  'leon-trotsky-80-anos': {
    backgroundColor: "black",
    color: "white",
  },
  'politicas-text': {
    color: theme.palette.politicas,
  },
  'economia-text': {
    color: theme.palette.economia,
  },
  'internacionales-text': {
    color: theme.palette.internacionales,
  },
  'movimiento-obrero-text': {
    color: theme.palette.movimiento,
  },
  'mujer-text': {
    color: theme.palette.mujer,
  },
  'sociedad-y-cultura-text': {
    color: theme.palette.sociedad,
  },
  'revista-text': {
    color: theme.palette.historia,
  },
  'archivo-text': {
    color: theme.palette.archivo,
  },
  'juventud-text': {
    color: theme.palette.juventud,
  },
  'correo-de-lectores-text': {
    color: theme.palette.correo,
  },
  'debate-text': {
    color: theme.palette.debate,
  },
  noBG:{
    backgroundColor: "inherit",
    fontSize: "0.8rem",
  },
}));

const SectionCard = ({ label, className, customColor, customPos, customTextColor }) => {
  const classes = useStyles();
  const sectionSlug = label;
  const secciones = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          secciones
        }
      }
    }
  `).site.siteMetadata.secciones;

  const seccionName = label === "revista" ? "POLÍTICA OBRERA REVISTA" : label === "leon-trotsky-80-anos" ? "LEÓN TROTSKY 80 AÑOS" : label === "congreso-politica-obrera" ? "Congreso de Política Obrera" : secciones.filter(item => slug(item) === label)[0];
  
  const classNameList = [ classes.root, className ];
  if (customColor) {
    classNameList.push(classes[sectionSlug]);
  }
  if (customPos) {
    classNameList.push(classes.customPos);
  }
  if (customTextColor) {
    classNameList.push(classes[`${sectionSlug}-text`]);
    classNameList.push(classes.noBG);
  }

  return (
    <Box className={classNameList.join(' ')}>
      <Link href={`/${label !== "revista" && label !== "leon-trotsky-80-anos" ? 'seccion/':''}${sectionSlug}`} className={classes.label}>
        {seccionName}
      </Link>
    </Box>
  );
};

SectionCard.propTypes = {
  customColor: PropTypes.bool,
  customPos: PropTypes.bool,
  customTextColor: PropTypes.bool,
};

SectionCard.defaultProps = {
  customColor: true,
  small: false,
  customPos: false,
  customTextColor: false,
};

export default SectionCard;
