import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const { getCadenaAutores } = require('../utils/tools.js');

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "0.9rem",
    marginTop: "0.8rem",
    strong: {
      fontWeight: "bold",
    },
  },
  dark: {
    color: "black",
  },
  light: {
    color: "white",
  },
  searchCard:{
    marginTop: "0.4rem",
    [theme.breakpoints.up('md')]: {
      marginTop: "1rem",
    }
  },
}));

const Autor = ({ names, searchCard, theme }) => {
  const autores = getCadenaAutores(names);
  const classes = useStyles();

  return (
    <Typography className={`${classes.root} ${classes[theme]} ${searchCard ? classes.searchCard: ''}`} component="p">
      {`${autores === "" ? '' : names.length > 1 ? 'Escriben ' : 'Escribe '}`}<strong>{autores}</strong>
    </Typography>
  );
};

Autor.propTypes = {
  theme: PropTypes.oneOf([ 'dark', 'light' ]),
  names: PropTypes.array,
  searchCard: PropTypes.bool,
};

Autor.defaultProps = {
  theme: 'dark',
  names: [],
  searchCard: false,
};

export default Autor;
